import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useFootnotes, FootnoteLink, Footnotes } from "../components/hooks/use-footnotes"

const footnotes = [
    "E.M. Van Wagner, et al., Effect of crossflow testing conditions, including feed pH and continuous feed filtration, on commercial reverse osmosis membrane performance, J. Membr. Sci. 345 (2009) 97–109.",
    "C.L. Ritt, et al., Ionization behavior of nanoporous polyamide membranes, Proc. Natl. Acad. Sci. U.S.A. 117 (2020) 30191–30200.",
    "V. Freger, Swelling and morphology of the skin layer of polyamide composite membranes: an atomic force microscopy study, Environ. Sci. Technol. 38 (2004) 3168–3175.",
    "D.M. Davenport, et al., Thin film composite membrane compaction in high-pressure reverse osmosis, J. Membr. Sci. 610 (2020) 118268.",
    "W.H. Zhang, et al., Graphene oxide membranes with stable porous structure for ultrafast water transport, Nat. Nanotechnol. 16 (2021) 337–343.",
    "L. Song, S. Yu, Concentration polarization in cross-flow reverse osmosis, AlChE J 45 (1999) 921–928.",
    "A. Szymczyk, P. Fievet, Investigating transport properties of nanofiltration membranes by means of a steric, electric and dielectric exclusion model, J. Membr. Sci. 252 (2005) 77–88.",
    "DuPont Water Solutions, <a href='https://www.dupont.com/content/dam/dupont/amer/us/en/water-solutions/public/documents/en/45-D01516-en.pdf'>BW30 product data sheet</a>. Accessed 2021.",
    "DuPont Water Solutions, <a href='https://www.dupont.com/content/dam/dupont/amer/us/en/water-solutions/public/documents/en/45-D01519-en.pdf'>SW30 product data sheet</a>. Accessed 2021.",
    "Toray, <a href='https://www.water.toray/products/ro/pdf/TM700D_v0_202104.pdf'>TM700D product data sheet</a>. Accessed 2021.",
    "Toray, <a href='https://www.water.toray/products/ro/pdf/TM800M_v0_202104.pdf'>TM800M product data sheet</a>. Accessed 202",
]

const StandardizationOfMembranePerformanceTesting = () => {

    const [notes] = useFootnotes(footnotes)

    return (
        <Layout>
            <Seo title='Membrane performance testing standards from the Open Membrane Database' />
            <section className="section">
                <div className="container is-max-desktop">
                    <h1 className="title is-spaced">Membrane performance testing standards</h1>
                    <p className="mb-3">
                        Membrane performance highly depends on the experimental conditions, such as solution pH, salt concentration, applied hydraulic pressure, and concentration polarization. These conditions often vary in many reports and can strongly influence membrane ionization and swelling behavior, membrane compaction, and transport modeling <FootnoteLink footnotes={notes} start={1} end={7} /> . In addition, comparing data obtained from large module-scale systems to small bench-scale systems can be tenuous. <b>Consequently, comparison of data from the OMD should beconducted with careful consideration of the data source</b>.
                    </p>
                    <p className="mb-3">
                        We strongly encourage the membrane community to follow industry standard testing conditions for RO membranes (see <b>Table 1</b>) to avoid disparities in reporting that originate from the operational conditions. The standardization of membrane performance characterization will strongly facilitate comparison of the data and will allow more accurate derivation of membrane synthesis-structure-performance relationships and other meta-analyses.
                    </p>
                </div>
            </section>
            <section className="section">
                <div className="container is-max-desktop has-text-centered">
                    <figure>
                        <figcaption><b>Table 1.</b> Standard testing conditions for seawater and brackish water RO processes.
                        </figcaption>
                        <table className="table is-bordered is-inline-block mt-3">
                            <thead>
                                <tr>
                                    <th>Testing condition</th>
                                    <th>Seawater RO</th>
                                    <th>Brackish water RO</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Feed salinity (ppm NaCl)
                                    </td>
                                    <td>32 000
                                    </td>
                                    <td>2,000
                                    </td>
                                </tr>
                                <tr>
                                    <td>Feed temperature (°C)</td>
                                    <td>25</td>
                                    <td>25</td>
                                </tr>
                                <tr>
                                    <td>Hydraulic pressure (bar)</td>
                                    <td>55.15</td>
                                    <td>15.5</td>
                                </tr>
                                <tr>
                                    <td>Filtration mode</td>
                                    <td>Cross-flow</td>
                                    <td>Cross-flow</td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan={3} className="is-size-7">
                                        <p>Note: Standard testing conditions are based on industry-reported conditions found in product datasheets <FootnoteLink footnotes={notes} start={8} end={10} />.</p>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </figure>

                </div>
            </section>
            <section className='section'>
                <div className="container is-max-desktop">
                    <Footnotes footnotes={footnotes} />
                </div>
            </section>
        </Layout >
    )
}

export default StandardizationOfMembranePerformanceTesting
